<template>
  <div class="aboutus">
    <div class="bannerA">
      <img
        class="banimg"
        src="@/assets/images/introduce/introduce-header.png"
      />
    </div>
    <div class="bannerB">
      <el-tabs v-model="activeName" id="introduction">
        <el-tab-pane label="公司简介" name="first">
          <div id="banner-top">
            <div class="header-box">深圳写手智能科技有限公司</div>
            <div class="header-str">
              Shenzhen writer Intelligent Technology Co., Ltd
            </div>
            <div class="header-hd">
              <div class="header-left">
                <div class="header-left1">
                  <p>
                    深圳写手智能科技有限公司成立于2020年06月08日，是一家以大数据知识服务为定位，为内容创作生态提供高品质服务的科创公司。目前，主打产品是“反抄袭”“成语”两个软件，均将陆续推出网站版本、PC版本、APP版本。
                  </p>
                  <p>
                    “反抄袭”致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。同时，提供智能网盘、版权收益、版权服务等一站式知识产权服务，并有执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体等的尚方宝剑。
                  </p>
                  <p>
                    “成语”致力于为用户在内容创作时推荐出合适的、高品质的内容，自动标注内容出处来源，形成高效规范的引文尾注，以更好促进尊重原创、激发灵感。同时，提供金句、模板、智能网盘、一键采集、数据分析、溯源、OCR、划词成语等丰富的内容创作配套工具，是大学生、教师、公职人员、记者、编辑、文员、笔杆子等的必备神器。
                  </p>
                </div>
                <div @click="contactUs" class="header-my">联系我们</div>
              </div>

              <div class="header-right">
                <img src="../../assets/images/introduce/01.png" alt="" />
              </div>
            </div>
          </div>
          <div class="fooder-box">
            <img src="../../assets/images/introduce/02.png" alt="" />
          </div>
        </el-tab-pane>
        <el-tab-pane label="软件定位" name="second">
          <div class="banner-tops">
            <div id="banner-top">
              <div
                class="banner-first"
                @mouseover="bannerFirst = true"
                @mouseleave="bannerFirst = false"
              >
                <div :class="bannerFirst ? 'banner-fooder' : 'banner-fd'">
                  <div>
                    <div>
                      <img
                        v-show="!bannerFirst"
                        src="@/assets/images/introduce/16.png"
                        alt=""
                      />
                      <img
                        v-show="bannerFirst"
                        src="@/assets/images/introduce/13.png"
                        alt=""
                      />
                    </div>
                    <div class="banner-title">原创保护</div>
                    <div class="banner-text">
                      致力于为原创文字作品、图片、视频、文件等提供电子数据指纹，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="banner-second"
                @mouseover="bannerSecond = true"
                @mouseleave="bannerSecond = false"
              >
                <div :class="bannerSecond ? 'banner-fooder' : 'banner-fd'">
                  <div>
                    <div>
                      <img
                        v-show="bannerSecond"
                        src="@/assets/images/introduce/14.png"
                        alt=""
                      />
                      <img
                        v-show="!bannerSecond"
                        src="@/assets/images/introduce/17.png"
                        alt=""
                      />
                    </div>
                    <div class="banner-title">版权收益</div>
                    <div class="banner-text">
                      原创作者可以通过作品授权，自主设定授权价格，获得现金等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="banner-third"
                @mouseover="bannerThird = true"
                @mouseleave="bannerThird = false"
              >
                <div :class="bannerThird ? 'banner-fooder' : 'banner-fd'">
                  <div>
                    <div>
                      <img
                        v-show="bannerThird"
                        src="@/assets/images/introduce/15.png"
                        alt=""
                      />
                      <img
                        v-show="!bannerThird"
                        src="@/assets/images/introduce/18.png"
                        alt=""
                      />
                    </div>
                    <div class="banner-title">版权维护</div>
                    <div class="banner-text">
                      提供智能网盘、版权收益、版权服务等一站式知识产权服务，由执业律师提供标准化的流程公开、价格透明的咨询与实务服务。
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="fooder-box1">
            <div class="header">实时数据</div>
            <div class="header-hd">Real time data</div>
            <div class="timemarker" ref="xqBox">
              <div class="box">
                <div class="nub">122098566<span>/篇</span></div>
                <div class="box2"></div>
                <div>反抄袭数据库</div>
              </div>
              <div class="box">
                <div class="nub">9090523<span>/篇</span></div>
                <div class="box3"></div>
                <div>今日新增</div>
              </div>
              <div class="box1">
                <div class="nub">102055<span>/人</span></div>
                <div class="box4"></div>
                <div>正在使用</div>
              </div>
            </div>
          </div> -->
        </el-tab-pane>
        <el-tab-pane label="功能板块" name="third">
          <div class="third" v-if="activeName == 'third'">
            <el-carousel
              :class="(index + 1) % 2 == 0 ? 'bgc' : ''"
              arrow="always"
              :interval="4000"
              height="610px"
              v-for="(item, index) in third"
              :key="index"
            >
              <el-carousel-item
                v-for="(item1, index1) in item.child"
                :key="index1"
              >
                <div
                  :class="[
                    (index + 1) % 2 == 0 ? 'even' : '',
                    index1 == 1 ? 'item2' : 'item1'
                  ]"
                >
                  <div
                    class="item_text_box"
                    :class="[
                      (index + 1) % 2 == 0 && index1 == 1
                        ? 'translate_lose_X'
                        : 'translate_is_X',
                      index + 1 >= 4 ? 'w' : '',
                      index + 1 == 5 ? 'h' : ''
                    ]"
                  >
                    <div class="title">
                      {{ item1.title
                      }}<span>{{ item1.span ? item1.span : "" }}</span>
                    </div>
                    <div class="text">
                      {{ item1.text }}
                    </div>
                  </div>
                  <div class="item_img_box" :class="item1.className">
                    <img :src="item1.src" alt="" />
                  </div>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </el-tab-pane>
      </el-tabs>
      <el-dialog
        title="联系方式"
        :modal="false"
        :visible.sync="dialogVisible"
        :before-close="handleClose"
      >
        <div class="dialog-introduce">
          <div>
            <img src="../../assets/images/introduce/08.png" alt="" />
          </div>
          <div class="particulars">
            <div class="div">联系电话:</div>
            <div>0755-23345494</div>
            <div>13828581514(颜先生)</div>
          </div>
        </div>
        <div class="dialog-introduce1">
          <div>
            <img src="../../assets/images/introduce/09.png" alt="" />
          </div>
          <div class="particulars">
            <div class="div">E-mail:</div>
            <div>writemall@126.com</div>
          </div>
        </div>
      </el-dialog>
    </div>
    <div class="bannerC">
      <div class="fooder-top">
        <div class="fooder-left">
          <div class="fooder-hd">
            <!-- <img src="../../assets/images/01/06.png" alt="" /> -->
            <img src="../../assets/images/introduce/04.png" alt="" />
          </div>
          <div class="fooder-bd">
            <div class="fooder-conter">
              <div @click="experience(1)">溯源</div>
              <div @click="experience(2)">原创存证</div>
              <div @click="experience(3)">智能网盘</div>
            </div>
            <div class="fooder-conter1">
              <div @click="experience(4)">版权收益</div>
              <div @click="experience(5)">版权服务</div>
              <div @click="experience(6)">关于我们</div>
            </div>
          </div>
        </div>
        <div class="fooder-right">
          <div>联系我们</div>
          <div class="right-box">0755-23345494</div>
          <div class="img_box">
            <div class="imgcode">
              <img
                class="img"
                src="@/assets/images/download-guide/02.png"
                alt=""
              />
              公众号
            </div>

            <div class="imgcode">
              <img src="@/assets/images/download-guide/01.png" alt="" />
              <!-- <img src="../../assets/images/introduce/06.png" alt="" /> -->
              企业微信
            </div>
          </div>
        </div>
      </div>
      <div class="fooder-bottom">
        深圳写手智能科技有限公司 Shenzhen writer Intelligent Technology Co., Ltd
        <a href="https://beian.miit.gov.cn/" target="_blank"
          >粤ICP备20062435号</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "introduce",
  data() {
    return {
      activeName: "first",
      dialogVisible: false,
      bannerFirst: false,
      bannerSecond: false,
      bannerThird: false,

      third: [
        {
          nama: "溯源",
          child: [
            {
              title: "溯源",
              text: "溯源是对文字作品、图片、视频、文件等版权作品的追根溯源，通过匹配电子数据指纹，进而确定最早的原创作者。反抄袭数据库，单一电子数据指纹，仅保留最早的原创作者。",
              src: require("assets/images/introduce/31.png"),
              className: "img"
            },
            {
              title: "溯源",
              span: "(典型场景)",
              text: "百度内容知名博主王小锤，其下有多位签约作者，严格要求原创作品，却频频遭遇“剪刀作”、跨平台“洗稿作”，甚至遭遇作品下架，即便发现抄袭也因为稿费已付难以追回，如何最快速有效判断原创率是其最重要的工作。这种情况下，可以采用反抄袭的溯源功能，快速评判可供参考的原创率结果。目前，文字作品溯源价格是0.3元/千字。",
              src: require("assets/images/introduce/19.png")
            }
          ]
        },
        {
          nama: "原创存证",
          child: [
            {
              title: "原创存证",
              text: "原创存证是对文字作品、图片、视频、文件等版权作品，适配国家授时中心时间戳，形成周密稳固的知识产权证据链，以更好保护原创作者。",
              src: require("assets/images/introduce/32.png"),
              className: "img1"
            },
            {
              title: "原创存证",
              span: "(典型场景)",
              text: "小红书知名大V王大锤，凌晨三点半终于完稿了“论内容生产力的重塑经验”一文，热度蹭蹭蹭往上涨，随即遭遇多个平台的未授权转载、洗稿等行为，诉诸法庭则举证繁杂、时间精力消耗极大。借助时间定位到秒级的原创存证功能则，可以轻松应对版权侵权诉讼，而且在作品后晒出对应的原创存证证书也可以起到很好的威慑作用。目前，所有原创存证，均为0.98元/篇次。",
              src: require("assets/images/introduce/24.png")
            }
          ]
        },
        {
          nama: "智能网盘",
          child: [
            {
              title: "智能网盘",
              text: "智能网盘主要是存储原创存证、云文档、收藏文档等常用存储功能。智能方面，主要是在用户同意授权情况下，可以对图片、PDF文档、CAJ文档自动进行OCR识别处理，并自动进行内容分类分析，有且只能用于用户本人的溯源、智能内容推荐等功能。",
              src: require("assets/images/introduce/28.png"),
              className: "img2"
            },
            {
              title: "智能网盘",
              span: "(典型场景)",
              text: "大学生王微锤，临近毕业在各大图书馆、网站搜集了一堆材料，word文档、PDF文档、PPT文档、CAJ文档等各种类型都有，临到用时想不起来相关内容在哪个文档。使用智能网盘，则可以轻松获得相应内容，并直接推荐出来（部分功能需要下载成语客户端）。",
              src: require("assets/images/introduce/25.png")
            }
          ]
        },
        {
          nama: "版权收益",
          child: [
            {
              title: "版权收益",
              text: "版权收益是指原创作者可以通过作品授权，自主设定授权价格，获得现金、字符包等收益。文字作品的最早原创者，在所有溯源中支付的字符数，涉及到篇、段、句的层面，都将直接获得重复字段的字符数。",
              src: require("assets/images/introduce/29.png"),
              className: "img3"
            },
            {
              title: "版权收益",
              span: "(典型场景)",
              text: "微信公众号知名博主王中锤，耗费三月有余创作了5000字的《时间就是第一生产力》，文笔优美、金句频频，随后被各种花式洗稿、拆分得七零八落，投诉、举报乃至诉讼均难以奏效。王中锤将《时间就是第一生产力》，上传至写手智能科技公司版权作品数据库。随后，某媒体机构在审核其拟刊发的3000字的《时间生产力》一文时，向写手智能科技公司支付了3000个字符，溯源发现其中2800字符均出自于王中锤的《时间就是第一生产力》，则王中锤可以自写手智能科技公司转获得2800字符，该字符可以直接提现。目前，字符提现价格为0.25元/千字。",
              src: require("assets/images/introduce/26.png")
            }
          ]
        },
        {
          nama: "版权服务",
          child: [
            {
              title: "版权服务",
              text: "版权服务主要是围绕知识产权提供版权实务、信息汇编、实名认证、收录确权、会员服务等一站式服务，由知名的知识产权领域执业律师提供标准化的流程公开、价格透明的咨询与实务服务，是原创作者、知识博主、杂志期刊与媒体机构、新媒体的重要助手。",
              src: require("assets/images/introduce/30.png"),
              className: "img4"
            },
            {
              title: "版权服务",
              span: "(典型场景)",
              text: "原创作者王巨锤的多个作品被众多平台、网站侵权，但其个人时间精力都有限，压根难以维权，但是委托律师又觉得费用高昂、难以掌控评判律师实际工作，因此只能得过且过、眼不见为净。但在反抄袭软件上的执业律师，则可以大为放心，全部按照标准化的流程公开进行处理，事项进展可以像看快递到哪了一样方便。更核心的，你可以根据侵权事项复杂程度、利益受损程度、价格承受能力，自主选择不同价位的律师，所有价格公开透明。更实在的是，你可以分阶段委托、分阶段付费，随时解聘随时续聘，历史数据与工作流程不受影响、无缝衔接，营造超级安心舒适的用户体验感。目前，知识产权方面的信息汇编是9.9元/年。",
              src: require("assets/images/introduce/27.png")
            }
          ]
        }
      ]
    };
  },
  mounted() {
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    scrollHandle() {
      // console.log(this.$refs.xqBox.getBoundingClientRect().top);
    },
    handleClose() {
      this.dialogVisible = false;
    },
    experience(val) {
      if (val == 1) {
        this.$router.push({
          name: "TraceTheSource"
        });
      } else if (val == 2) {
        this.$router.push({
          name: "Store"
        });
      } else if (val == 3) {
        this.$router.push({
          name: "disk"
        });
      } else if (val == 4) {
        this.$router.push({
          name: "copyrightIncome"
        });
      } else if (val == 5) {
        this.$router.push({
          name: "copyrightService"
        });
      } else {
        this.$router.push({
          name: "aboutus"
        });
      }
    },
    contactUs() {
      this.dialogVisible = true;
    },
    handleClick() {}
  }
};
</script>

<style lang="scss" scoped>
body {
  background-color: #f0f2f5;
}

.aboutus {
  width: 100%;
  position: absolute;
  left: 0;

  .bannerA {
    width: 100%;
    height: 450px;
    position: relative;
    margin-top: -30px;

    .banimg {
      width: 100%;
      height: 100%;
    }
  }
  a {
    color: #000;
  }
  .bannerB {
    margin-bottom: -1px;
    width: 100%;
    #introduction {
      color: #000;
      width: 100%;

      ::v-deep .el-tabs__header {
        padding: 0 199px;
        height: 122px;
        box-sizing: border-box;
        .el-tabs__nav-wrap {
          height: 100%;
          .el-tabs__nav-scroll {
            height: 100%;
            .el-tabs__nav {
              height: 100%;
              line-height: 122px;
              text-align: center;
              .el-tabs__item {
                font-size: 25px;
                font-weight: 500;
                padding: 0 30px;
                // margin-left: 19px;
              }
              .el-tabs__active-bar {
                width: 160px !important;
                margin-left: -30px;
                height: 3px;
              }
            }
          }
        }
      }
      #pane-first {
        text-align: center;
        #banner-top {
          padding: 0 199px;

          box-sizing: border-box;
          .header-box {
            width: 100%;
            height: 44px;
            font-size: 46px;
            margin-top: 82px;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #333333;
          }
          .header-str {
            width: 100%;
            height: 27px;
            font-size: 28px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #999999;
            margin-top: 17px;
            line-height: 19px;
            opacity: 0.3;
          }
          .header-hd {
            width: 100%;
            height: 724px;
            padding: 69px 148px 4.5% 149px;
            box-sizing: border-box;
            .header-left {
              float: left;
              position: relative;
              width: 50%;
              height: 540px;

              font-size: 16px;
              color: #888888;
              line-height: 34px;
              text-align: justify;
              .header-left1 {
                max-height: 450px;
                overflow-y: auto;
              }
              /*滚动条bai整体*/
              ::-webkit-scrollbar {
                width: 2px; /*滚动条宽度*/
              }
              ::-webkit-scrollbar-track {
                background-color: #fff; /*滑道全部*/
              }
              ::-webkit-scrollbar-track-piece {
                background-color: #fff; /*滑道*/
              }
              ::-webkit-scrollbar-thumb {
                background-color: #fafafa; /*滑动条表面*/
                border: none; /*滑动条边框*/
              }
              p {
                text-indent: 2em;
              }
              .header-my {
                cursor: pointer;
                position: absolute;
                left: 0;
                bottom: 0;
                width: 159px;
                height: 56px;
                color: #fff;
                text-align: center;
                line-height: 56px;
                font-size: 22px;
                background-color: #4587ff;
              }
            }
            .header-right {
              float: right;
              width: 47%;
              height: 540px;
              img {
                width: 100%;
                height: calc(100% - 9px);
                margin-top: 9px;
              }
            }
          }
        }

        .fooder-box {
          width: 100%;
          height: 567px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      #pane-second {
        width: 100%;
        .banner-tops {
          width: 100%;
          margin-bottom: 227px;
          text-align: center;
          padding: 105px 228px 0 228px;
          box-sizing: border-box;
          #banner-top {
            margin: 0 auto;
            display: flex;
            // width: 1466px;
            height: 352px;
            box-shadow: 0px 27px 46px 0px rgba(0, 0, 0, 0.19);
            .banner-first {
              // width: 489px;
              width: 100%;
              height: 352px;
              .banner-fd {
                cursor: pointer;
                // width: 489px;
                width: 100%;
                height: 352px;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #555555;
                img {
                  width: 45px;
                  height: 54px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #053690;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #555;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
              .banner-fooder {
                cursor: pointer;
                width: 100%;
                // width: 489px;
                height: 352px;
                background: url("../../assets/images/introduce/10.png")
                  no-repeat;
                background-size: 100% 100%;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #fff;
                img {
                  width: 45px;
                  height: 54px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #ffffff;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #fff;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
            }
            .banner-second {
              // width: 489px;
              width: 100%;

              height: 352px;
              .banner-fd {
                cursor: pointer;
                width: 100%;
                // width: 489px;
                height: 352px;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #555555;
                img {
                  width: 48px;
                  height: 49px;
                  margin-bottom: 5px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #053690;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #555;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
              .banner-fooder {
                cursor: pointer;
                width: 100%;
                // width: 489px;
                height: 352px;
                background: url("../../assets/images/introduce/11.png")
                  no-repeat;
                background-size: 100% 100%;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #fff;
                img {
                  width: 48px;
                  height: 49px;
                  margin-bottom: 5px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #ffffff;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #fff;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
            }
            .banner-third {
              // width: 489px;
              width: 100%;
              height: 352px;
              .banner-fd {
                cursor: pointer;
                width: 100%;
                // width: 489px;
                height: 352px;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #555555;
                img {
                  width: 48px;
                  height: 50px;
                  margin-bottom: 4px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #053690;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #555;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
              .banner-fooder {
                cursor: pointer;
                width: 100%;
                // width: 489px;
                height: 352px;
                background: url("../../assets/images/introduce/12.png")
                  no-repeat;
                background-size: 100% 100%;
                text-align: center;
                padding: 57px 70px 50px 70px;
                box-sizing: border-box;
                color: #fff;
                img {
                  width: 48px;
                  height: 50px;
                  margin-bottom: 4px;
                }
                .banner-title {
                  font-size: 24px;
                  font-family: Source Han Sans CN;
                  font-weight: 800;
                  color: #ffffff;
                  line-height: 35px;
                  margin: 30px 0 10px;
                }
                .banner-text {
                  font-size: 14px;
                  font-family: PingFang SC;
                  font-weight: 500;
                  color: #fff;
                  line-height: 30px;
                  text-align-last: center;
                  max-height: 150px;
                  overflow-y: auto;
                }
              }
            }
          }
        }

        .fooder-box1 {
          margin-top: 227px;
          width: 100%;
          height: 450px;
          padding: 0 197px;
          box-sizing: border-box;
          background-image: url("../../assets/images/introduce/20.png");
          background-size: 100% 100%;
          text-align: center;
          overflow: hidden;
          .header {
            font-size: 41px;
            font-family: Source Han Sans CN;
            font-weight: 800;
            color: #ffffff;
            margin-top: 67px;
          }
          .header-hd {
            font-size: 24px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #ffffff;
            opacity: 0.1;
            margin-top: 15px;
          }
          .timemarker {
            display: flex;
            justify-content: center;
            margin-top: 97px;
            font-size: 18px;
            color: #ffffff;
            .box {
              position: relative;
              text-align: center;
              width: 382px;
              height: 100%;
              margin-right: 200px;
              .box2 {
                position: absolute;
                top: -30px;
                left: 0;
                margin-left: 91px;
                width: 200px;
                height: 150px;
                background-image: url("../../assets/images/introduce/21.png");
                background-size: 100% 100%;
              }
              .box3 {
                position: absolute;
                top: -45px;
                left: 0;
                margin-left: 106px;
                width: 150px;
                height: 180px;
                background-image: url("../../assets/images/introduce/22.png");
                background-size: 100% 100%;
              }
            }
            .box1 {
              position: relative;
              text-align: center;
              width: 382px;
              height: 100%;
              .box4 {
                position: absolute;
                top: -25px;
                left: 0;
                margin-left: 86px;
                width: 190px;
                height: 140px;
                background-image: url("../../assets/images/introduce/23.png");
                background-size: 100% 100%;
              }
            }
            .nub {
              font-size: 45px;
              font-family: Source Han Sans CN;

              margin-bottom: 29px;
              span {
                font-size: 30px;
                font-weight: 400;
              }
            }
          }
        }
      }

      .third {
        .el-carousel {
          .el-carousel__item {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            .item1 {
              justify-content: space-evenly;
              width: 100%;
              display: flex;
              align-items: center;

              .item_text_box {
                width: 540px;

                .title {
                  font-size: 42px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #333333;
                }
                .text {
                  margin-top: 30px;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 30px;
                }
              }

              .img {
                img {
                  width: 573px;
                  height: 376px;
                }
              }
              .img1 {
                img {
                  width: 551px;
                  height: 393px;
                }
              }

              .img2 {
                img {
                  width: 586px;
                  height: 391px;
                }
              }
              .img3 {
                img {
                  width: 542px;
                  height: 430px;
                }
              }
              .img4 {
                img {
                  width: 637px;
                  height: 410px;
                }
              }
            }

            .even {
              flex-direction: row-reverse;
            }
            .translate_is_X {
              transform: translateX(20%);
            }
            .translate_lose_X {
              transform: translateX(-20%);
            }

            .item2 {
              display: flex;
              align-items: center;

              .item_text_box {
                box-sizing: border-box;
                padding: 60px;
                width: 622px;
                height: 366px;
                background-color: #fbfbfb;
                .title {
                  font-size: 42px;
                  font-family: PingFang SC;
                  font-weight: bold;
                  color: #333333;

                  span {
                    font-size: 20px;
                  }
                }
                .text {
                  margin-top: 30px;
                  font-size: 18px;
                  font-family: PingFang SC;
                  font-weight: 400;
                  color: #666666;
                  line-height: 30px;
                }
              }

              .w {
                width: 719px;
                padding: 50px;
                .text {
                  font-size: 16px;
                }
              }

              .h {
                padding: 40px;
                height: 380px;
              }

              .item_img_box {
                img {
                  width: 761px;
                  height: 442px;
                }
              }
            }
          }
        }

        .bgc {
          background-color: #f8fafe;
        }

        ::v-deep .el-carousel__indicators {
          .el-carousel__button {
            width: 11px;
            height: 11px;
            border-radius: 50%;
            background-color: #7aa9ff;
          }
        }
      }
    }
    /deep/ .el-dialog {
      width: 659px;
      height: 415px;
      margin: auto;
      padding-left: 15px;
      .el-dialog__header {
        .el-dialog__title {
          display: inline-block;
          font-size: 26px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #393939;
        }
        .el-dialog__headerbtn {
          .el-dialog__close {
            font-size: 30px;
            font-weight: 500;
          }
        }
      }
      .dialog-introduce {
        margin-top: 18px;
        display: flex;
        img {
          width: 29px;
          height: 30px;
          margin-right: 33px;
        }
        .particulars {
          div {
            margin-top: 5px;
            font-size: 20px;
            margin-bottom: 25px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
          }
          .div {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #393939;
          }
        }
      }
      .dialog-introduce1 {
        margin-top: 35px;
        display: flex;
        img {
          width: 29px;
          height: 21px;
          margin-right: 33px;
          margin-top: 5px;
        }
        .particulars {
          div {
            margin-top: 5px;
            font-size: 20px;
            margin-bottom: 25px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #393939;
          }
          .div {
            font-size: 22px;
            font-family: PingFang SC;
            font-weight: bold;
            color: #393939;
          }
        }
      }
    }
  }

  .bannerC {
    width: 100%;
    height: 432px;
    margin-top: 35px;
    color: #000;
    // color: #fff;
    margin-bottom: 42px;
    // background: url("../../assets/images/01/03.png") no-repeat;
    background-image: url("../../assets/images/introduce/03.png");
    background-size: 100% 100%;
    padding: 50px 347px 0 348px;
    box-sizing: border-box;
    .fooder-top {
      display: flex;
      height: 100%;
      height: 290px;
      // border-bottom: 1px solid #242424;
      border-bottom: 1px solid #edeff5;
      .fooder-left {
        flex: 1;
        .fooder-hd {
          margin-left: -8px;
          img {
            width: 206px;
            height: 68px;
            margin-bottom: 49px;
          }
        }
        .fooder-bd {
          display: flex;
          justify-content: space-between;
          width: 60%;
          font-size: 16px;
          .fooder-conter {
            div {
              cursor: pointer;
              margin: 0 0px 39px 0;
            }
          }
          .fooder-conter1 {
            div {
              cursor: pointer;
              margin-bottom: 39px;
            }
          }
        }
      }
      .fooder-right {
        flex: 1;
        text-align: right;
        font-size: 26px;
        font-family: PingFang SC;
        font-weight: 800;
        // color: #fff;
        color: #000;
        .right-box {
          margin: 21px 0 55px;
        }

        .img_box {
          display: flex;
          flex-direction: row-reverse;
          .imgcode {
            margin-left: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            font-weight: 500;

            img {
              margin-bottom: 10px;
              width: 90px;
              height: 90px;
            }

            .img {
              width: 96px;
              height: 96px;
            }
          }
        }
      }
    }
    .fooder-bottom {
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 500;
      // color: #fff;
      color: #000;
      line-height: 30px;
      text-align: right;
    }
  }
}
</style>
